import React, { Component } from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import Receipt from '../../../../../assets/images/module/general/ReceiptDummy.png';
import PdfViewer from '../PdfViewer/PdfViewer';
import { Row, Col, Form, Tooltip, OverlayTrigger } from 'react-bootstrap';
import CustomModal from '.';
import { showNotification } from '@mantine/notifications';
import ColorButton from '../Buttons/ColorButton';
import CopyIcon from '../../../SVGs/copyIcon';
import GreenTick from '../../../../../assets/images/module/general/GreenTick.svg';
import { Checkbox, Group, Stack, TextInput } from '@mantine/core';
import EmptyStateContainer from '../EmptyStateContainer/EmptyStateContainer';
import TenantIconBlue from '../../../../../assets/images/module/general/TenantIconBlue.svg';
import Bank from '../../../../../assets/images/module/general/Bank.svg';
import { NumericFormat } from 'react-number-format';
import SearchContainer from '../SearchContainer/SearchContainer';

const MOBILE_SIZE = 992;
const TABLET_SIZE = 1200;

export class AddBankConnectionModal extends Component {
  state = {
    isMobile: window.innerWidth <= MOBILE_SIZE,
    isTablet: window.innerWidth <= TABLET_SIZE,
    search: null,
    banks: [
      {
        id: '1',
        name: 'Sparkasse',
        icon: Bank
      },
      {
        id: '2',
        name: 'Deutsche Bank AG',
        icon: Bank
      },
      {
        id: '3',
        name: 'Commerzbank',
        icon: Bank
      },
      {
        id: '4',
        name: 'Postbank',
        icon: Bank
      }
    ],
    selectedBank: null
  };
  
  constructor(props) {
    super(props);
  }
  
  componentDidMount(){
  }
  
  render() {
    const { open, toggle, className, setSelectedBank } = this.props;
    const { search, banks, selectedBank } = this.state;
    
    return (
      <CustomModal
        heading={'Add bank connection'}
        headingClassName={'add-bank-connection-modal-heading'}
        open={open}
        toggle={() => {
          toggle();
        }}
        className={`${className}`}
      >
        <Stack>
          <Group>
          <div className='filters' style={{width: '100%'}}>
            <div className='section-1' style={{width: '100%'}}>
              <SearchContainer
              value={search}
              onChange={(e)=> this.setState({search: e.target.value})}
              disabled={false}
              placeholder={'Suche nach Bank'}
              style={{width: '100%'}}
              />
            </div>
          </div>
          </Group>
          <Group>
            {banks.filter((bank)=> search?.length > 0 ? bank.name.toLowerCase().includes(search.toLowerCase()) : bank)
            .map((bank)=>
              <Stack className={`bank-cards ${selectedBank === bank.id && 'selected-bank'}`} align={'center'} justify={'space-between'} onClick={()=> this.setState({selectedBank: bank.id})} spacing={10}>
                <img src={bank.icon} style={{width: '90px'}}/>
                <span>{bank.name}</span>
              </Stack>
            )}
          </Group>
          <Group position='apart' style={{marginTop: '20px'}}>
            <div>
                <ColorButton
                text={'Abrechen'}
                className='gray-btn'
                onClick={()=> toggle()}
                />
            </div>
            <div>
                <ColorButton
                text={'Weiter'}
                className='purple-btn'
                onClick={()=> setSelectedBank(selectedBank)}
                disabled={selectedBank === null}
                />
            </div>
          </Group>
        </Stack>
      </CustomModal>
    );
  }
}

export default AddBankConnectionModal;
