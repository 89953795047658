import React, { Component } from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import Receipt from '../../../../../assets/images/module/general/ReceiptDummy.png';
import PdfViewer from '../PdfViewer/PdfViewer';
import { Row, Col, Form, Tooltip, OverlayTrigger } from 'react-bootstrap';
import CustomModal from '.';
import { showNotification } from '@mantine/notifications';
import ColorButton from '../Buttons/ColorButton';
import CopyIcon from '../../../SVGs/copyIcon';
import GreenTick from '../../../../../assets/images/module/general/GreenTick.svg';
import { Checkbox, CloseButton, Group, Loader, MultiSelect, Select, Stack, TextInput } from '@mantine/core';
import EmptyStateContainer from '../EmptyStateContainer/EmptyStateContainer';
import TenantIconBlue from '../../../../../assets/images/module/general/TenantIconBlue.svg';
import RentIconBlue from '../../../../../assets/images/module/general/RentIcon.svg';
import RentIcon from '../../../../../assets/images/module/general/RentIconGray.svg';
import ReceiptsIcon from '../../../../../assets/images/module/general/ReceiptsIconGray.svg';
import ReceiptsIconBlue from '../../../../../assets/images/module/general/ReceiptsIconBlue.svg';
import CloseButtonIcon from '../../../../../assets/images/module/general/CloseButtonIcon.svg';
import Up from '../../../../../assets/images/module/general/SelectArrowUp.svg';
import Down from '../../../../../assets/images/module/general/SelectArrowDown.svg';
import EmptyIcon from '../../../../../assets/images/EmptyReciept.svg';
import { NumericFormat } from 'react-number-format';
import CardSelection from '../CardSelection/CardSelection';
import { connect } from 'react-redux';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import Autoload from '../../../../Autoload';
import dayjs from 'dayjs';
import accounting from 'accounting';
import SelectDropdown from '../SelectDropdown/SelectDropdown';
import SelectReceiptsForTransactionAssigmentModal from './SelectReceiptsForTransactionAssigmentModal';
import SwitchContainer from '../SwitchContainer';

const MOBILE_SIZE = 992;
const TABLET_SIZE = 1200;
var Setting = Autoload.Setting;
var lang = Setting[Setting.language_default];

const tableCellStyle = {
    whiteSpace: 'nowrap', 
    overflow: 'hidden', 
    textOverflow: 'ellipsis', 
    fontSize: '14px', 
    fontFamily: 'Inter, sans-serif', 
    height: 'auto !important',
    fontWeight: '400',
    borderTop: '8px solid white'
}

function Value({
    value,
    label,
    onRemove,
    classNames,
    ...others
  }) {
    console.log('Value: ', value, label, others);
    return (
        <Group spacing={5} className='message-card-ten-cards' key={value} noWrap style={{height: '20.6px', maxHeight: '20.6px', background: '#F2F2FC', paddingRight: others.name.length > 4 ? 10 : 3, marginRight: '3px'}}>
            <Group noWrap spacing={5} style={{minWidth: '55px'}}>
                <span style={{whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden'}}>{others.name}</span>
            </Group>
            <Group>
                <CloseButton
                onMouseDown={onRemove}
                variant="transparent"
                size={15}
                iconSize={14}
                tabIndex={-1}
                />
            </Group>
        </Group>
    );
}

export class AssignTransactionModal extends Component {
  state = {
    isMobile: window.innerWidth <= MOBILE_SIZE,
    isTablet: window.innerWidth <= TABLET_SIZE,
    hover1: false,
    hover2: false,
    hover3: false,
    hover4: false,
    selectedType: null,
    tableHeader: ['Mieter', 'Mieteinheit', 'Einzug', 'Auszug'],
    receiptsTableHeader: ['Name', 'Betrag (€)', 'Datum'],
    selectedTenant: null,
    selectedReceiptType: null,
    receiptsList: [],
    selectedReceipts: [],
    receiptsLoader: false,
    vatTypes: [
        {label: '19,00 %'},
        {label: '7,00 %'},
        {label: 'anderer'}
    ],
    taxCostTypes: [
    // {label: 'Abschreibungen für GWG'},
    {label: 'Schuldzinsen'},
    {label: 'Geldbeschaffungskosten'},
    {label: 'Erhaltungsaufwendungen'},
    {label: 'Laufende Betriebskosten'},
    {label: 'Verwaltungskosten'},
    {label: 'Sonstige Kosten'},

    {label: 'Erstattete Vorauszahlungen'},
    {label: 'Erhaltene Nachzahlungen'},
    {label: 'Vereinnahmte Kautionen / Mieten aus Vorjahren'},
    {label: 'Vom Finanzamt erstattete Umsatzsteuer'},
    {label: 'Öffentliche Zuschüsse'},
    {label: 'AfA Gebäude'},
    {label: 'AfA bewegliche Wirtschaftsgüter'},
    {label: 'An Finanzamt gezahlte Umsatzsteuer'},
    {label: 'Renten, dauernde Lasten'},
    ],
    selectedVatType: [],
    customVatType: '0%',
    selectedTaxCostType: [],
    vatAmount: null, 
    netAmount: null,
    netInFocus: false,
    vatInFocus: false,
    vatPercentInFocus: false,
    selectReceiptsForTransactionAssigmentModalOpen: false,
    autoReceiptCreation: false,
    tenants: [],
    dropdownIcon: Down
  };

  constructor(props) {
    super(props);
  }

  componentDidMount(){
    const {rentalUnits} = this.props;
    let tenants = [];
    rentalUnits.map((rentalUnit)=> rentalUnit.tenantsData.map((tenant)=> {
        if(tenant.vacant === false){
            tenants.push({...tenant, rentalUnitName: rentalUnit.name, value: tenant.id, label: `${rentalUnit.name}: ${tenant.name} (${tenant.in} - ${tenant.out ? tenant.out : 'aktuell'})`});
        }
    }))
    this.setState({tenants});
  }

  handleMouseEnter=(type)=>{
    this.setState({[type]: true});
  }

  handleMouseLeave=(type)=>{
      this.setState({[type]: false});
  }

  getReceipts=()=>{
    Autoload.HttpRequestAxios.HttpRequest(
        Autoload.Setting.rm_api_base_url + 'property/' + this.props.propertySelected.id,
        'GET',
        null,
        null,
        false,
        true,
        false,
        this,
        'handleGetReceipts',
        null,
        false,
        '',
        {
          'Content-Type': 'application/json',
          Authorization: Autoload.Utils.getAuthToken(),
        },
        true
      );
  }

  handleGetReceipts(response){
    if(response.status && response.statuscode === 200){
        this.setState({receiptsList: response.result.map((receipt)=> ({...receipt, value: receipt.id, label: receipt.name}))});
    }
    this.setState({receiptsLoader: false});
  }
  
  render() {
    const { open, toggle, className, rentalUnits } = this.props;
    const { hover1, hover2, hover3, hover4, selectedType, isMobile, selectedReceiptType, receiptsLoader, receiptsList, selectedReceipts, selectedTenant, vatTypes, selectedVatType, selectedTaxCostType, customVatType, netAmount, vatAmount, netInFocus, vatInFocus, vatPercentInFocus, selectReceiptsForTransactionAssigmentModalOpen, autoReceiptCreation, tenants, dropdownIcon } = this.state;
    
    console.log('rentalUnits: ', rentalUnits);
    console.log('receiptsList: ', receiptsList);
    console.log('selectedReceipts_1234: ', selectedReceipts);

    console.log('tenants: ', tenants);
    return (
      <CustomModal
        heading={'Art der Zahlung'}
        headingClassName={'assign-transaction-modal-heading'}
        open={open}
        toggle={() => {
          toggle();
        }}
        className={`${className}`}
      >
        <Stack spacing={20}>
            <Group grow noWrap style={{margin: '20px 0px 5px'}}>
                <div>
                    <CardSelection
                    title={'Ausgabe'}
                    subText={'Lorem ipsum dolor sit amet. Ut voluptas quam est sequi.'}
                    icon={RentIcon}
                    blueIcon={RentIconBlue}
                    hover={hover1}
                    hoverName={'hover1'}
                    selectedType={selectedType}
                    type={'CREDIT_DEBIT_BALANCE'}
                    disabled={false}
                    onClick={()=> this.setState({selectedType: 'CREDIT_DEBIT_BALANCE'}, ()=> this.getReceipts())}
                    handleMouseEnter={(hoverName)=> this.handleMouseEnter(hoverName)}
                    handleMouseLeave={(hoverName)=> this.handleMouseLeave(hoverName)}
                    isMobile={isMobile}
                    //typeError={typeError}
                    />
                </div>
                <div>
                    <CardSelection
                    title={'Mieteinnahme'}
                    subText={'Lorem ipsum dolor sit amet. Ut voluptas quam est sequi.'}
                    icon={RentIcon}
                    blueIcon={RentIconBlue}
                    hover={hover2}
                    hoverName={'hover2'}
                    selectedType={selectedType}
                    type={'RENTAL_INCOME'}
                    disabled={false}
                    onClick={()=> this.setState({selectedType: 'RENTAL_INCOME'})}
                    handleMouseEnter={(hoverName)=> this.handleMouseEnter(hoverName)}
                    handleMouseLeave={(hoverName)=> this.handleMouseLeave(hoverName)}
                    isMobile={isMobile}
                    //typeError={typeError}
                    />
                </div>
            </Group>
            {selectedType === 'RENTAL_INCOME' &&
            <Stack>
                <Group>
                    <span className='modal-sub-headings'>Mieter auswählen</span>
                </Group>
                <Group grow>
                    {/* <TableContainer>
                        <Table>
                            <TableHead>
                                {this.getTableHeader()}
                            </TableHead>
                            <TableBody>
                                {this.getTableBody()}
                            </TableBody>
                        </Table>
                    </TableContainer> */}
                    <Select
                    placeholder="Wählen"
                    onChange={(value)=> this.setState({selectedTenant: value})}
                    data={tenants}
                    styles={{
                        dropdown: {
                            display: 'block'
                          },
                          item: {
                            color: '#0e0e1d',
                            fontSize: '14px',
                            fontWeight: '500',
                            fontFamily: 'sans-serif'
                          }
                    }}
                    className={'multi-select-messages-tenants'}
                    classNames={{
                        input: 'tenant-transaction-selection-input'
                    }}
                    rightSection={<img src={dropdownIcon}/>}
                    onDropdownOpen={()=> this.setState({dropdownIcon: Up})}
                    onDropdownClose={()=> this.setState({dropdownIcon: Down})}
                    zIndex={9999999}
                    />
                </Group>
            </Stack>}

            {selectedType === 'CREDIT_DEBIT_BALANCE' &&
            <Stack>
                <Group>
                    <span className='modal-sub-headings'>Beleg erstellen oder zuordnen</span>
                </Group>
                <Group grow onClick={()=> this.setState({selectReceiptsForTransactionAssigmentModalOpen: true})}>
                    <MultiSelect
                    data={receiptsList}
                    value={selectedReceipts}
                    className='multi-select-bank-transactions-receipt-assignment'
                    placeholder="Wählen"
                    styles={{
                        disabled: { background: 'white', cursor: 'pointer' },
                        input: {opacity: 1},
                        rightSection: {marginRight: '10px'},
                        dropdown: {display: 'none'},
                        rightSection: {display: 'none'}
                    }}
                    />
                </Group>
                <Group>
                    <SwitchContainer
                    switchText={'Einstellungen merken und für Zahlungen mit IBAN [IBAN] zukünftig automatisch Beleg erzeugen'}
                    checked={autoReceiptCreation}
                    onChange={()=> this.setState({autoReceiptCreation: !autoReceiptCreation})}
                    disabled={selectedReceipts.length === 0}
                    />
                </Group>
            </Stack>}
            <Group position='apart'>
                <div>
                    <ColorButton
                    text={'Abrechen'}
                    className='gray-btn'
                    onClick={()=> toggle()}
                    />
                </div>
                <div>
                    <ColorButton
                    text={'Speichern'}
                    className='purple-btn'
                    onClick={()=> toggle()}
                    disabled={selectedReceipts.length === 0 && selectedTenant === null}
                    />
                </div>
            </Group>
        </Stack>
        {selectReceiptsForTransactionAssigmentModalOpen &&
        <SelectReceiptsForTransactionAssigmentModal
        open={selectReceiptsForTransactionAssigmentModalOpen}
        toggle={()=> this.setState({selectReceiptsForTransactionAssigmentModalOpen: false})}
        selectedReceiptsFromParent={selectedReceipts}
        saveSelectedReceipts={(selectedReceipts)=> this.setState({selectedReceipts, selectReceiptsForTransactionAssigmentModalOpen: false})}
        getReceipts={()=> this.getReceipts()}
        />}
      </CustomModal>
    );
  }
}

const mapStateToProps = (state) => {
    return {
      propertySelected: state.generalReducer.property,
      rentalUnits: state.tenantDataReducer.rentalUnits
    };
  };
  
export default connect(mapStateToProps)(AssignTransactionModal);
