import React, { Component } from 'react';
import Autoload from '../../../../neoverv/Autoload';
import { connect } from 'react-redux';
import { showNotification } from '@mantine/notifications';
import CopyIcon from '../../../../neoverv/components/SVGs/copyIcon';
import { Box, Group, Modal, Overlay, Stack, Radio, RadioGroup, TextInput, Popper, Popover, CheckboxGroup, Checkbox } from '@mantine/core';
import { InputLabel, MenuItem, Select, FormControl, TableContainer, TableHead, TableBody, TableCell, TableRow, Table, Tooltip, IconButton } from '@material-ui/core';
import { ColorButton, DeleteModal, RecordOptions, SearchContainer, SwitchContainer } from '../../../../neoverv';
import PenIcon from '../../../../assets/images/module/general/PenIcon.svg';
import RedCross from '../../../../assets/images/module/general/RedCross.svg';
import {
  changeCurrentProperty
} from '../../../../redux/action/General/generalActions';
import {getRentalUnitTenants} from '../../../../redux/action/Tenant/TenantActions'
import dayjs from 'dayjs';
import moment from 'moment';
import { formatNumber } from '../../../../helpers/stringsManipulations';
import DatePicker from 'react-datepicker';
import { NumericFormat } from 'react-number-format';
import Icon from "../../../../components/Icon";
import UpdateTenantRentModal from '../../../../neoverv/components/Module/General/CustomModal/UpdateTenantRentModal';
import { load, unload } from "@finapi/web-form";
// import FinApiWebFormModal from '../../../../neoverv/components/Module/General/CustomModal/FinApiWebFormModal';
import { PageHeaderNew } from '../../../elements';
import accounting from 'accounting';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import FilterListIcon from '@material-ui/icons/FilterList';
import UpdateIcon from '@material-ui/icons/Update';
import AssignTransactionModal from '../../../../neoverv/components/Module/General/CustomModal/AssignTransactionModal';
import AddBankConnectionModal from '../../../../neoverv/components/Module/General/CustomModal/AddBankConnectionModal';

var Setting = Autoload.Setting;
var lang = Setting[Setting.language_default];
const MOBILE_SIZE = 992;
const TABLET_SIZE = 1420;

const dateRangeForAllTenants=[
  {
    value: 'current',
    label: `Aktueller Monat (${dayjs().format('MMMM YYYY')})`, 
    date: {
      start: dayjs().startOf('month'), 
      end: dayjs().endOf('month')
    }
  },
  {
    value: 'last',
    label: `Letzter Monat (${dayjs().subtract(1, 'month').format('MMMM YYYY')})`, 
    date: {
      start: dayjs().subtract(1, 'month').startOf('month'), 
      end: dayjs().subtract(1, 'month').endOf('month')
    }
  },
  {
    value: 'custom',
    label: `Benutzerdefiniert`, //(${dayjs().format('MMMM YYYY')})
    date: {
      start: new Date(moment().startOf('month')), 
      end: new Date(moment().endOf('month'))
    }
  },
]

const dateRangeForSpecificTenant=[
  {
    value: 'current',
    label: `Aktueller Jahr (${dayjs().startOf('year').format('MMMM YYYY')} - ${dayjs().endOf('month').format('MMMM YYYY')})`, 
    date: {
      start: dayjs().startOf('year'), 
      end: dayjs().endOf('month')
    }
  },
  {
    value: 'last',
    label: `Letztes Jahr (${dayjs().subtract(1, 'year').startOf('year').format('MMMM YYYY')} - ${dayjs().subtract(1, 'year').endOf('year').format('MMMM YYYY')})`, 
    date: {
      start: dayjs().subtract(1, 'year').startOf('year'), 
      end: dayjs().subtract(1, 'year').endOf('year')
    }
  },
  {
    value: 'custom',
    label: `Benutzerdefiniert`, //(${dayjs().startOf('year').format('MMMM YYYY')} - ${dayjs().endOf('month').format('MMMM YYYY')})
    date: {
      start: new Date(moment().startOf('year')), 
      end: new Date(moment().endOf('month'))
    }
  },
]
const {checkProperty} = Autoload.MainHelper;
let rentalUnits = [];
let rentalListData = [];
export class PaymentsList extends Component {
  openTenantSelectionModalRef = null;

  constructor(props) {
    super(props);
    dayjs.locale('de');
    this.state = {
      isMobile: window.innerWidth <= MOBILE_SIZE,
      isTablet: window.innerWidth <= TABLET_SIZE,
      tableHeaderLabels: ['Transaction', 'Booking Date', 'Amount', 'Reference', 'IBAN', ''],
      openWebFormModal: false,
      transactions: [
        {
          name: 'Ten1',
          bookingDate: dayjs().format('DD.MM.YYYY'),
          amount: 450,
          reference: '11023837578/ ----',
          iban: 'xxxx-xxxx-xxxx-xxxx-xxxx',
          bic: 'xxxxxxxx'
        },
        {
          name: 'Paypal',
          bookingDate: dayjs().format('DD.MM.YYYY'),
          amount: -240,
          reference: '11023837578/ ----',
          iban: 'xxxx-xxxx-xxxx-xxxx-xxxx',
          bic: 'xxxxxxxx'
        },
        {
          name: 'Paypal',
          bookingDate: dayjs().subtract(3, 'day').format('DD.MM.YYYY'),
          amount: -40,
          reference: '11023837578/ ----',
          iban: 'xxxx-xxxx-xxxx-xxxx-xxxx',
          bic: 'xxxxxxxx'
        },
        {
          name: 'Paypal',
          bookingDate: dayjs().subtract(3, 'day').format('DD.MM.YYYY'),
          amount: -80,
          reference: '11023837578/ ----',
          iban: 'xxxx-xxxx-xxxx-xxxx-xxxx',
          bic: 'xxxxxxxx'
        }
      ],
      assignTransactionsModalOpen: false,
      addBankConnectionModalOpen: false,
      selectedBank: null,
      deleteBankConnectionModalOpen: false
    };

  }

  componentDidMount(){
    if(this.props.rentalUnits.length === 0){
      this.props.getRentalUnitTenants([]);
      if(this.props.propertySelected && this.props.propertySelected.id !== undefined){
        this.bind_rental_unit_list_data();
      }
    }
  }

  bind_rental_unit_list_data = () => {
    let propertyid = this.props.propertySelected && this.props.propertySelected.id;
    // console.log('property id', propertyid,  Autoload.Setting.rental_unit_api_base_url+"?propertyId="+propertyid)
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.property_api_base_url + 'rentalUnits?offset=0&fetch=100&propertyId=' + propertyid,
      'GET',
      null,
      null,
      false,
      true,
      false,
      this,
      'handle_get_list_rental_unit',
      null,
      false,
      '',
      {
        // 'Content-Type': 'application/json',
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  };

  handle_get_list_rental_unit = (response) => {
    if (response.result === null || response.result && response.result.length === 0) {
      //this.ShowLoader();
    }

    console.log(response, 'add tenant response');
    this.props.getRentalUnitTenants([]);
    if (response.status === false) {
      
    }else{
      rentalUnits = response.result.map((ru) => ({
        id: checkProperty(ru, 'id'),
        name: checkProperty(ru, 'name'),
        size: checkProperty(ru, 'space'),
        propertyId: checkProperty(ru, 'propertyId'),
        tenantsData: [],
      }));
      response.result.map((ru) => {
        Autoload.HttpRequestAxios.HttpRequest(
          Autoload.Setting.property_api_base_url + 'tenants?offset=0&fetch=100&rentalUnitId=' + ru.id,
          'GET',
          null,
          null,
          false,
          true,
          false,
          this,
          'handle_get_list_rental_tenants',
          null,
          false,
          '',
          {
            //'Content-Type': 'application/json',
            Authorization: Autoload.Utils.getAuthToken(),
          }
        );
      }); 
    }
  };
  handle_get_list_rental_tenants = (response) => {
    rentalListData = this.props.rentalUnits;

    if (response.result.length > 0) {
      //sort for move out
      let dashMoveOut = response.result.filter((item) => item.out === '');
      let filledMovedout = response.result.filter((item) => item.out !== '');
      filledMovedout.map((item) => dashMoveOut.push(item));
      //let current = false;
      let tenantsData = [...dashMoveOut];
      // first vacant false check
      let isFirstVacantFound = false;
      //first vancant false = index
      let firstOriginalDataIndex;

      tenantsData = response.result.map((tenant, idx) => {
        console.log(tenant, idx, 'tenant current idx');
        // isVacant value
        let isVacant = tenant.vacant;
        let current = false;
        if (isFirstVacantFound == false && isVacant == false) {
          firstOriginalDataIndex = idx;
          current = true;
          isFirstVacantFound = true;
        }

        return {
          id: checkProperty(tenant, 'id'),
          name: checkProperty(tenant, 'fullName'),
          email: checkProperty(tenant, 'email'),
          phone: checkProperty(tenant, 'phone'),
          in: checkProperty(tenant, 'moveInDate') 
            ? moment(checkProperty(tenant, 'moveInDate')).format('DD.MM.YYYY')
            : '',
          out: checkProperty(tenant, 'moveOutDate')
            ? moment(checkProperty(tenant, 'moveOutDate')).format('DD.MM.YYYY')
            : '',
          type: checkProperty(tenant, 'contractType'),
          note: checkProperty(tenant, 'notes'),
          current: current,
          createdOn: checkProperty(tenant, 'createdOn'),
          vacant: checkProperty(tenant, 'vacant'),
          rentalUnitId: checkProperty(tenant, 'rentalUnitId'),
          updatedOn: checkProperty(tenant, 'updatedOn'),
        };
      });
      console.log(tenantsData, '======tenantsData');
      if (tenantsData.length === 1 && tenantsData[0].vacant == true) {
        tenantsData[0].current = true;
      } else if (firstOriginalDataIndex !== 0 && firstOriginalDataIndex !== undefined) {
        let moveoutDate = tenantsData[firstOriginalDataIndex].out;
        let dateCompare = moment(this.ChangeDateFormat(moveoutDate)).isSameOrAfter(moment(new Date()));
        console.log(moveoutDate, dateCompare, tenantsData[firstOriginalDataIndex], '====abc======');
        if (dateCompare === false) {
          tenantsData[firstOriginalDataIndex - 1].current = true;
          tenantsData[firstOriginalDataIndex].current = false;
        } else {
          tenantsData[firstOriginalDataIndex - 1].current = true;
        }
      }

      //action
      //row ko array
      let field = 'name';
      let rentalunitsorted = rentalUnits.sort((a, b) =>
        (a[field] || '').toString().localeCompare((b[field] || '').toString())
      );
      rentalUnits = rentalunitsorted.map((ru) => {
        if (ru.id == tenantsData[0].rentalUnitId) {
          return {
            ...ru,
            tenantsData: [...tenantsData],
          };
        } else {
          return ru;
        }
      });

      console.log('tenant of rental unit', tenantsData, rentalUnits);
    }

    //pass data received to redux
    //this.ShowLoader();
    this.props.getRentalUnitTenants(rentalUnits);
  };


  handleResize = () => {
    if (window.innerWidth <= MOBILE_SIZE) {
      this.setState({ isMobile: true });
    } else {
      this.setState({ isMobile: false });
    }
    if (window.innerWidth <= TABLET_SIZE) {
      this.setState({ isTablet: true });
    } else {
      this.setState({ isTablet: false });
    }
  };

  getTableHeader = () => {
    const {tableHeaderLabels} = this.state;

    return(
      <TableRow>
      {tableHeaderLabels.map((label)=>
        <TableCell style={{textAlign: label === 'Amount' && 'right'}}>
          {label}
        </TableCell>
      )}
      </TableRow>
    )
  };
  
  getTableBody = () => {
    const {transactions} = this.state;
    return transactions.map((transaction)=> (
      <TableRow style={{background: '#FFFFFF'}} onClick={()=> this.setState({assignTransactionsModalOpen: true})}>
        <TableCell width={'20%'} style={{height: '71px'}}>
          {transaction.name}
        </TableCell>
        <TableCell>
          {transaction.bookingDate}
        </TableCell>
        <TableCell width={'10%'} style={{textAlign: 'right'}}>
          <span style={{color: transaction.amount < 0 ? '#E8193C' : '#059266'}}>{accounting.formatMoney(transaction.amount, { symbol: "€",  format: "%v %s", precision: 2, thousand: '.', decimal: ',' })}</span>
        </TableCell>
        <TableCell>
          {transaction.reference}
        </TableCell>
        <TableCell>
          {transaction.iban}
        </TableCell>
        <TableCell>
          {/* {transaction.bic} */}
          <ColorButton
          text={'Beleg zuordnen oder erstellen'}
          className={'gray-btn'}
          onClick={()=> null}
          />
        </TableCell>
        {/* <TableCell>
          <RecordOptions
          listOfItems={[
              {
                text: 'Assign To Receipt',
                leftImage: 
                <EditIcon 
                style={{
                color: '#5655FE',
                fontSize: '15px'
                }}/>,
                openFunction: (()=> null)
              },
              {
                text: 'Assign To Rent',
                leftImage: 
                  <EditIcon 
                  style={{
                  color: '#5655FE',
                  fontSize: '15px'
                  }}/>,
                openFunction: (()=> null)
              }]
          }
          buttonStyle={{border: 'none', background: 'white'}}
          />
        </TableCell> */}
      </TableRow>
    ))
  };

  renderBankDetailsCard=(isActive=false)=>{
    return (
    <Group className='bank-details-card' spacing={26} noWrap>
      <Stack spacing={5}>
        <span className='bank-details-card-heading'>Account Details</span>
        <span className='bank-details-card-heading-2'>Sparkasse Berlin</span>
        <Stack spacing={0}>
          <span className='bank-details-card-account-name'>Account Name</span>
          <span className='bank-details-card-iban'>xxxx-xxxx-xxxx-xxxx-xxxx-xx</span>
        </Stack>
      </Stack>
      <Group position='right' noWrap>
        <span className='bank-details-card-account-balance'>2.564,83 €</span>
      </Group>
    </Group>);
  }

  actionButtons=()=> {
    return (
      <Group spacing={10} noWrap>
        {/* add new account */}
        <Tooltip title={'Hinzufügen'}>
          <IconButton 
            aria-label="filter list"
            onClick={()=> this.setState({addBankConnectionModalOpen: true})}
            style={{
              background: '#5655FE',
              border: '1px solid #DADAE5', 
              boxShadow: '0 1px 2px 0 rgba(14, 14, 29, .06)', 
              padding: '5px',
              cursor: 'pointer',
              marginRight: '10px',
              opacity: 1
              }}>
              <AddIcon 
              style={{
                color: 'white',
                fontSize: '15px'
                }}/>
          </IconButton>
        </Tooltip>
        {/* update account */}
        <Tooltip title={'Aktualisieren'}>
          <IconButton 
            aria-label="filter list"
            onClick={()=> this.openWebForm()}
            style={{
              background: 'white',
              border: '1px solid #DADAE5', 
              boxShadow: '0 1px 2px 0 rgba(14, 14, 29, .06)', 
              padding: '5px',
              cursor: 'pointer',
              marginRight: '10px',
              opacity: 1
              }}>
              <UpdateIcon 
              style={{
                color: '#5655FE',
                fontSize: '15px'
                }}/>
          </IconButton>
        </Tooltip>
        {/* delete account */}
        <Tooltip title={'Löschen'}>
          <IconButton 
          aria-label="filter list"
          //disabled={showRentalUnitsFolder || showTenantsFolders}
          onClick={()=> this.setState({deleteBankConnectionModalOpen: true})} 
          style={{
          background: 'white',
          border: '1px solid #DADAE5', 
          boxShadow: '0 1px 2px 0 rgba(14, 14, 29, .06)', 
          padding: '5px',
          cursor: 'pointer',
          marginRight: '10px',
          opacity: 1
          }}>
            <DeleteIcon 
            style={{
              color: '#E8193C',
              fontSize: '15px'
              }}/>
          </IconButton>
        </Tooltip>
        {/* filter */}
        <Tooltip title={'Filtern'}>
          <IconButton 
          aria-label="filter list"
          onClick={()=> null} 
          style={{
          background: 'white',
          border: '1px solid #DADAE5', 
          boxShadow: '0 1px 2px 0 rgba(14, 14, 29, .06)', 
          padding: '5px',
          cursor: 'pointer',
          opacity: 1
          }}>
            <FilterListIcon 
            style={{
            color: '#5655FE',
            fontSize: '15px'
            }}/>
          </IconButton>
        </Tooltip>
      </Group>
    );
  }

  openWebForm=()=>{
    this.setState({openWebFormModal: true});
  }

  deleteBankConnection=(id)=>{
    this.setState({deleteBankConnectionModalOpen: false});
  }

  render() {
    const {isMobile, isTablet, openWebFormModal, assignTransactionsModalOpen, addBankConnectionModalOpen, selectedBank, deleteBankConnectionModalOpen} = this.state;
    const {propertySelected} = this.props;

    return (
      <Stack id='owner-document' spacing={0}>
        <Group style={{marginTop: '20px'}} position='right'>
          {this.actionButtons()}
        </Group>
        <div className='divider' />
        <Stack style={{marginTop: '16px'}}>
          <Group spacing={0}>
            {this.renderBankDetailsCard()}
          </Group>
          <Group style={{marginTop: '20px'}}>
            <TableContainer>
              <Table className='rental-income-list-table'>
                <TableHead>
                  {this.getTableHeader()}
                </TableHead>
                <TableBody>
                  {this.getTableBody()}
                </TableBody>
              </Table>
            </TableContainer>
          </Group>
        </Stack>
        
        {addBankConnectionModalOpen &&
        <AddBankConnectionModal
        open={addBankConnectionModalOpen}
        toggle={()=> this.setState({addBankConnectionModalOpen: false})}
        setSelectedBank={(selectedBank)=> this.setState({selectedBank, addBankConnectionModalOpen: false}, ()=> this.openWebForm())}
        />}

        {assignTransactionsModalOpen &&
        <AssignTransactionModal
        open={assignTransactionsModalOpen}
        toggle={()=> this.setState({assignTransactionsModalOpen: false})}
        />}

        {/* {openWebFormModal &&
        <FinApiWebFormModal
        open={openWebFormModal}
        toggle={()=> this.setState({openWebFormModal: false})}
        />} */}

        {deleteBankConnectionModalOpen &&
        <DeleteModal
        open={deleteBankConnectionModalOpen}
        record={null}
        typeText={'Are sure you want to delete this bank connection?'}
        showtext={false}
        handleDelete={(id)=> this.deleteBankConnection(id)}
        toggle={()=> this.setState({deleteBankConnectionModalOpen: false})}
        showDeleteText={false}
        textShowModal={'Delete bank connection'}
        />}
      </Stack>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    propertySelected: state.generalReducer.property,
    currentSubscription: state.generalReducer.currentSubscription,
    accountData: state.generalReducer.accountData,
    rentalUnits: state.tenantDataReducer.rentalUnits,
  };
};

const actions = {
  changeCurrentProperty,
  getRentalUnitTenants
}

export default connect(mapStateToProps, actions)(PaymentsList);
