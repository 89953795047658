import React from 'react';
import SearchIcon from '../../../../../assets/images/module/general/SearchIcon.svg';

export default function SearchContainer({ className, onChange, value, placeholder, onBlur,onFocus,inputClassName, disabled }) {
  return (
    <div className={`search-filter ${className}`}>
      <img className="search-icon" src={SearchIcon} alt="search" />
      <input
        className={`search-input ${inputClassName}`}
        value={value}
        placeholder={placeholder}
        onChange={(e) => onChange(e)}
        disabled={disabled}
        onFocus={onFocus}
        onBlur={onBlur}
      />
    </div>
  );
}
SearchContainer.defaultProps = {
  value: '',
  placeholder: '',
  onChange: () => {},
  disabled: {},
  onFocus:()=>{},
  onBlur:()=>{}
};
