import React from 'react';

export default function FiltersContainer({ sortElement,switchElement,searchElement, style }) {
    return (
        <div className='filters' style={style}>
            <div className='section-1'>
                {searchElement}
            </div>
            <div className='section-2'>
                {switchElement && 
                <div className='switch-filter'>
                    {switchElement}
                </div>}
            </div>
            <div className='section-3'>
                <div className='sort-filter'>
                    {sortElement}
                </div>
            </div>
        </div>
    );
}
